.glassy-card {
  /* width: 350px;
height: 350px; */
  border: 0.3px solid #ffffff94;
  border-radius: 0.625rem !important;
  background: rgb(255 255 255 / 10%) !important;
  backdrop-filter: blur(6.6px);
  -webkit-backdrop-filter: blur(8.6px);
  /* border: 1px solid rgba(255, 255, 255, 0.21); */
  padding: 0 0.6rem;

  box-shadow: 2px 2px 7px 0px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 2px 2px 7px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 2px 2px 7px 0px rgba(0, 0, 0, 0.3);
  /* margin-bottom: 5rem; */
}

.glassy-card-inner {
  /* From https://css.glass */
  background: rgb(255 255 255 / 22%);
  border-radius: 0.2rem !important;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(6.6px);
  -webkit-backdrop-filter: blur(8.6px);
  border: 0.3px solid #ffffff94;
}

.prdouct-image {
  width: auto !important;
  height: 300px !important;
}

.left-arrow,
.right-arrow {
  width: 35px;
}

.sizes {
  background: rgb(255 255 255 / 10%) !important;
  border: 0.5px solid #009696;
  border-radius: 0.25rem;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sizes:hover{
  background-image: linear-gradient(
    to top,
    #f3f3f3a3,
    #f1f3f8a3,
    #ecf5fca3,
    #e4f8fda3,
    #defafaa3
  ) !important;
  border: 0.5px solid #009696;
}

.add-to-cart-btn:active,
.add-to-cart-btn:hover{
  background: rgb(217,217,217) !important;
background: linear-gradient(0deg, rgba(217,217,217,1) 0%, rgba(231,230,230,1) 50%, rgba(170,209,208,0.9360994397759104) 99%) !important;
  border: 0.5px solid #009696;
}
.add-to-cart-btn {
  height: 35px;
  width: 120px;
  background-image: linear-gradient(
    to top,
    #f3f3f3a3,
    #f1f3f8a3,
    #ecf5fca3,
    #e4f8fda3,
    #defafaa3
  );
  border: 0.5px solid #009696;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.25rem;
}

.active-size-btn{
  background: rgb(217,217,217) !important;
background: linear-gradient(0deg, rgba(217,217,217,1) 0%, rgba(231,230,230,1) 50%, rgba(170,209,208,0.9360994397759104) 99%) !important;
  border: 0.5px solid #009696;
}
/* .ant-btn-primary:active,
.ant-btn-primary:focus,
.ant-btn-primary:hover, */
.submit-btn-solid:hover,
.submit-btn-solid:focus,
.submit-btn-solid:active,
.submit-btn-solid {
  height: 32px;
  color: #000;
  width: 120px;
  background-image: linear-gradient(
    to top,
    #f3f3f3,
    #f1f3f8,
    #ecf5fc,
    #e4f8fd,
    #defafa
  );
  border: 0.5px solid #009696;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.25rem;
}

.cart-item-wrapper {
  background-color: #ffffffa0;
}

.faq * {
  font-family: "IBM Plex Mono", monospace;
}

.window-icons {
  padding: 0 12px;
  border: 0.3px solid #ffffffb3;
  border-right: 0;
  border-top: 0;
  background: rgb(255 255 255 / 6%) !important;
  backdrop-filter: blur(6.6px);
  -webkit-backdrop-filter: blur(8.6px);
}

.window-inner-icon{
  margin-bottom: 4px;
}

.br-1 {
  border-right: 0.3px solid #ffffffb3;
}

.bbrr {
  border-bottom-right-radius: 6px;
}

.bblr {
  border-bottom-left-radius: 6px;
}

@media (max-width: 576px) {
  .sizes {
    max-width: 30px;
    max-height: 30px;
  }
  .add-to-cart-btn {
    max-height: 30px;
  }
  .product-img {
    height: 225px;
  }
  .contact-title {
    font-size: 0.95rem;
  }
  .cart-icon {
    width: 55px;
}
}

@media (max-width: 768px) {
  .ml-md-6 {
    margin-left: 3.8rem;
  }
}


.product-title{
  font-size: 1.6rem;
  font-weight: 350;
}
.product-price{
  font-size: 1.7rem;
  font-weight: 350;
}